<template>
	<BaseLoading v-if="bankChannel.edit.isLoading" is-full-page />
	<PaymentChannelForm
		v-else
		:logo="data.logo"
		:name-en="data.nameEN"
		:name-th="data.nameTH"
		:status="data.isActive"
		:is-submitting="bankChannel.edit.isUpdating"
		is-edit
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import PaymentChannelForm from '@/components/PaymentChannelForm.vue';

export default {
	name: 'PaymentCreditCardBankChannelEdit',

	components: {
		PaymentChannelForm,
	},
	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
		...mapState('payments', {
			bankChannel: 'bankChannel',
		}),

		...mapGetters({
			data: 'payments/bankChannelDetails',
		}),
	},
	async created() {
		await this.getBankChannel({
			methodId: this.$route.params.methodId,
			channelId: this.$route.params.channelId,
		});

		this.setPageTitle({
			title: this.data.nameEN,
			isShow: true,
		});
	},
	methods: {
		...mapActions({
			getBankChannel: 'payments/getBankChannel',
			updateBankChannel: 'payments/updateBankChannel',
			setPageTitle: 'pageTitle/setPageTitle',
		}),

		async handleSubmit(params = {}) {
			await this.updateBankChannel({
				methodId: this.$route.params.methodId,
				channelId: this.$route.params.channelId,
				params,
			});
		},
	},
};
</script>
